import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'

import './src/styles/global.scss'
import './src/styles/fonts.scss'

import Layout from './src/layout/Layout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  const { location } = props
  const isHomePage = ['/', '/en/'].includes(location.pathname)
  const lang = location.pathname.startsWith('/en/') ? 'en' : 'es'

  return (
    <Layout isHomePage={isHomePage} lang={lang}>
      {element}
    </Layout>
  )
}
