import React from 'react'
import { Link } from 'gatsby'
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'

import type { NavigationItem, SocialMediaItem } from '@custom-types'
import Logo from '@images/logo.svg'

type FooterProps = {
  homeLink: '/' | '/en/'
  navigation: NavigationItem[]
  socialMedia: SocialMediaItem[]
}

const icons = {
  Facebook: <FaFacebookF className="h-5 w-5" />,
  Instagram: <FaInstagram className="h-5 w-5" />,
  TikTok: <FaTiktok className="h-5 w-5" />,
  YouTube: <FaYoutube className="h-5 w-5" />,
}

const Footer = ({ homeLink, navigation, socialMedia }: FooterProps) => {
  return (
    <footer className="bg-primary pt-12 text-white md:pt-16">
      <div className="container">
        <div className="row gap-8 pb-12 md:gap-0 md:pb-16">
          <div className="flex justify-center text-xl md:col-4 md:justify-start">
            <Link to={homeLink} className="flex h-12 w-32 justify-center text-xl md:justify-start">
              <span className="sr-only">Dentitoy</span>
              <Logo className="h-full fill-white" aria-hidden="true" />
            </Link>
          </div>

          <ul className="grid gap-6 text-center font-light md:col-4 md:grid-cols-2 md:grid-rows-3 md:gap-2 md:text-left">
            {navigation.map((item, index) => {
              const linkIsExternal = item.link.startsWith('http')

              return (
                <li key={index}>
                  {linkIsExternal ? (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.name}
                    </a>
                  ) : (
                    <Link to={item.link} state={{ openModal: item.modal }}>
                      {item.name}
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>

          <ul className="flex justify-center gap-8 md:col-4 md:justify-end">
            {socialMedia.map((item, index) => (
              <li key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <span className="sr-only">{item.name}</span>
                  {icons[item.name]}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="border-t border-white/25 py-8 text-center text-xs font-light md:font-medium">
          <p className="tracking-widest">© 2023 DENTITOY | DESARROLLADO POR WHIZ</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
