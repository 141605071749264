import React, { useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'
import { globalHistory } from '@reach/router'
import { IoMdMenu, IoMdClose } from 'react-icons/io'
import { useLocation } from '@reach/router'

import type { NavigationItem } from '@custom-types'
import Logo from '@images/logo.svg'

type HeaderProps = {
  homeLink: '/' | '/en/'
  navigation: NavigationItem[]
  color?: 'white' | 'black'
}

const Header = ({ homeLink, navigation, color }: HeaderProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(prev => !prev)
  }

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset'
  }, [open])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setOpen(false)
      }
    })
  }, [setOpen])

  const logoColorClass = color === 'white' ? 'fill-white' : 'fill-primary'
  const textColorClass = color === 'white' ? 'text-white' : 'text-primary'

  const location = useLocation()

  const changeLanguage = (lang: 'es' | 'en') => {
    const isEnglish = location.pathname.startsWith('/en/')

    const path = isEnglish ? location.pathname.substring(3) : location.pathname

    if (lang == 'es') {
      navigate(path)
    } else {
      navigate('/en'.concat(path))
    }
  }

  return (
    <header className="absolute z-30 flex h-16 w-full items-center overflow-hidden md:h-24">
      <div className="container max-w-none xl:px-12">
        <div className="flex items-center">
          <Link to={homeLink} className="z-10 flex h-8 w-20 justify-center text-xl md:h-12 md:w-32">
            <span className="sr-only">Dentitoy</span>
            <Logo
              className={`h-full transition-colors duration-500 ease-in-out ${open ? 'fill-white' : logoColorClass}`}
              aria-hidden="true"
            />
          </Link>

          <div
            className={`
              z-10 ml-auto flex gap-1 px-4 text-xl font-medium md:ml-0 md:mr-auto md:px-8 md:text-2xl
              ${open ? 'text-white' : textColorClass}
            `}
          >
            <button
              onClick={() => changeLanguage('es')}
              className={`
                transition-colors duration-500 ease-in-out ${homeLink === '/' ? 'opacity-100' : 'opacity-70'}
              `}
            >
              ES
            </button>
            <span className="transition-colors duration-500 ease-in-out">/</span>
            <button
              onClick={() => changeLanguage('en')}
              className={`
                transition-colors duration-500 ease-in-out ${homeLink === '/en/' ? 'opacity-100' : 'opacity-70'}
              `}
            >
              EN
            </button>
          </div>

          <button onClick={handleClick} className="relative z-10 min-h-full">
            <span className="sr-only">Menu</span>
            <IoMdClose
              className={`
                relative left-0 top-0 h-8 w-8 fill-white transition-all duration-500 md:h-12 md:w-12
                ${open ? 'opacity-100' : 'translate-x-7 opacity-0'}
              `}
              aria-hidden="true"
            />
            <IoMdMenu
              className={`
                absolute left-0 top-0 h-8 w-8 transition-all duration-500 md:h-12 md:w-12
                ${logoColorClass}
                ${!open ? 'opacity-100' : '-translate-x-7 opacity-0'}
              `}
              aria-hidden="true"
            />
          </button>

          <div
            id="menu"
            className={`
              fixed left-0 top-0 h-screen w-screen bg-primary text-white transition-transform duration-500 ease-in-out
              ${open ? 'translate-x-0' : 'translate-x-full'}
            `}
          >
            <nav className="mr-4 md:mr-12">
              <ul className="space-y-9 pt-24 text-right text-2xl font-bold md:pt-32 md:text-5xl">
                {navigation.map((item, index) => {
                  const linkIsExternal = item.link.startsWith('http')

                  return (
                    <li key={index}>
                      {linkIsExternal ? (
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          {item.name}
                        </a>
                      ) : (
                        <Link to={item.link} state={{ openModal: item.modal }}>
                          {item.name}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
