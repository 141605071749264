import { useStaticQuery, graphql } from 'gatsby'
import type { NavigationItem, SocialMediaItem, ContactItem } from '@custom-types'

type GeneralInfoQueryResult = {
  directus: {
    general_translations: {
      languages_code: {
        code: string
      }
      navigation: NavigationItem[]
      contactInfo: ContactItem[]
      socialMedia: SocialMediaItem[]
    }[]
  }
}

const useGeneralInfo = (lang?: 'es' | 'en') => {
  const data = useStaticQuery<GeneralInfoQueryResult>(graphql`
    query ContactInfo {
      directus {
        general_translations {
          languages_code {
            code
          }
          navigation
          contactInfo: contact
          socialMedia: social_media
        }
      }
    }
  `)

  const code = lang === 'es' ? 'es-ES' : 'en-US'

  return data.directus.general_translations.filter(t => t.languages_code.code === code)[0]
}

export default useGeneralInfo
