exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acciones-en-tsx": () => import("./../../../src/pages/acciones.en.tsx" /* webpackChunkName: "component---src-pages-acciones-en-tsx" */),
  "component---src-pages-acciones-tsx": () => import("./../../../src/pages/acciones.tsx" /* webpackChunkName: "component---src-pages-acciones-tsx" */),
  "component---src-pages-contacto-gracias-en-tsx": () => import("./../../../src/pages/contacto/gracias.en.tsx" /* webpackChunkName: "component---src-pages-contacto-gracias-en-tsx" */),
  "component---src-pages-contacto-gracias-tsx": () => import("./../../../src/pages/contacto/gracias.tsx" /* webpackChunkName: "component---src-pages-contacto-gracias-tsx" */),
  "component---src-pages-contacto-index-en-tsx": () => import("./../../../src/pages/contacto/index.en.tsx" /* webpackChunkName: "component---src-pages-contacto-index-en-tsx" */),
  "component---src-pages-contacto-index-tsx": () => import("./../../../src/pages/contacto/index.tsx" /* webpackChunkName: "component---src-pages-contacto-index-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-en-tsx": () => import("./../../../src/pages/nosotros.en.tsx" /* webpackChunkName: "component---src-pages-nosotros-en-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-productos-en-tsx": () => import("./../../../src/pages/productos.en.tsx" /* webpackChunkName: "component---src-pages-productos-en-tsx" */),
  "component---src-pages-productos-tsx": () => import("./../../../src/pages/productos.tsx" /* webpackChunkName: "component---src-pages-productos-tsx" */)
}

