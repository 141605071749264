import React, { PropsWithChildren } from 'react'
import Header from './Header'
import Footer from './Footer'
import ScrollToTopButton from './ScrollToTopButton'
import SocialMediaButtons from './SocialMediaButtons'
import useGeneralInfo from '../hooks/useGeneralInfo'

type Props = {
  isHomePage: boolean
  lang?: 'es' | 'en'
} & PropsWithChildren

const Layout: React.FC<Props> = ({ children, isHomePage, lang }) => {
  const { navigation, socialMedia } = useGeneralInfo(lang)
  const homeLink = lang === 'es' ? '/' : '/en/'

  return (
    <>
      <Header homeLink={homeLink} navigation={navigation} color={isHomePage ? 'white' : 'black'} />
      {children}
      <Footer homeLink={homeLink} navigation={navigation} socialMedia={socialMedia} />

      <SocialMediaButtons socialMedia={socialMedia} color={isHomePage ? 'white' : 'black'} />
      <ScrollToTopButton />
    </>
  )
}

export default Layout
