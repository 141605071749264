import React from 'react'
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa'
import type { SocialMediaItem } from '@custom-types'

type SocialMediaButtonsProps = {
  socialMedia: SocialMediaItem[]
  color?: 'white' | 'black'
}

const icons = {
  Facebook: <FaFacebookF className="h-5 w-5" />,
  Instagram: <FaInstagram className="h-5 w-5" />,
  TikTok: <FaTiktok className="h-5 w-5" />,
  YouTube: <FaYoutube className="h-5 w-5" />,
}

const SocialMediaButtons = ({ socialMedia, color }: SocialMediaButtonsProps) => {
  const colorClass = color === 'white' ? 'text-white' : 'text-primary'

  return (
    <ul
      className={`absolute right-12 top-48 z-20 hidden w-12 flex-col items-center gap-8 rounded-full xl:flex ${colorClass}`}
    >
      {socialMedia.map((item, index) => (
        <li key={index}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <span className="sr-only">{item.name}</span>
            {icons[item.name]}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default SocialMediaButtons
