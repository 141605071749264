import React from 'react'
import { useWindowScroll, useViewportSize } from '@mantine/hooks'
import Arrow from '@images/arrow.svg'

const ScrollToTopButton = () => {
  const [scroll, scrollTo] = useWindowScroll()
  const { height } = useViewportSize()

  const isVisible = scroll.y > height / 4
  const isDisabled = scroll.y === 0

  return (
    <button
      onClick={() => scrollTo({ y: 0 })}
      disabled={isDisabled}
      className={`
        fixed bottom-4 right-4 z-10 grid h-12 w-12 place-items-center rounded-full bg-white shadow-2xl shadow-black transition-all duration-300 ease-in-out md:bottom-12 md:right-12 
        ${isVisible ? 'opacity-100' : 'translate-x-4 opacity-0 md:translate-x-12'}
      `}
    >
      <span className="sr-only">Volver al inicio</span>
      <Arrow aria-hidden />
    </button>
  )
}

export default ScrollToTopButton
